import React from 'react';
import './modules/Common.css';
import Box from '@mui/material/Box';
import { setConfiguration } from 'react-grid-system';


import bgImage0 from './assets/images/0.jpg'
import bgImage1 from './assets/images/1.jpg'
import bgImage2 from './assets/images/2.jpg'
import bgImage3 from './assets/images/3.jpg'
import bgImage4 from './assets/images/4.jpg'
import bgImage5 from './assets/images/5.jpg'
import bgImage6 from './assets/images/6.jpg'

import NotVerifiedUserModule from './modules/NotVerifiedUserModule';
import PasswordExpired from './modules/PasswordExpired';
import GuestAccessRejected from "./modules/GuestAccessRejected";
import InsufficientDetails from "./modules/InsufficientDetails";
import ProgressiveForm from "./modules/ProgressiveForm";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import UserBlockedByApp from "./modules/UserBlockedByApp"

const appInsights = new ApplicationInsights({ config: {
    instrumentationKey: 'e4e94bbb-d6e5-46dd-a353-3417093b0f63'
    /* ...Other Configuration Options... */
  } });
appInsights.loadAppInsights();
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview


const telemetryInitializer = (envelope) => {
  envelope.tags["ai.cloud.role"] = "auth0-static-app";
  // envelope.tags["ai.cloud.roleInstance"] = "your role instance";
  //TODO other fields
}
appInsights.addTelemetryInitializer(telemetryInitializer);


setConfiguration({ maxScreenClass: 'xl' });

const loadModule= (p, appInsights) =>{
  appInsights.trackPageView({name: p.pagetype});

  if (p.pagetype === "unverifiedUser"){
    return <NotVerifiedUserModule/>
  }

  if (p.pagetype === "userPasswordExpired"){
    return <PasswordExpired/>
  }

  if (p.pagetype === "guestAccessRejected"){
    return <GuestAccessRejected/>
  }

  if (p.pagetype === "insufficientDetails"){
    return <InsufficientDetails/>
  }

  if (p.pagetype === "nonGuest" || p.pagetype === "guest"){
    return <ProgressiveForm pagetype={p.pagetype} appInsights={appInsights}/>
  }
  if (p.pagetype === "userblockedbyapplication"){
    return <UserBlockedByApp/>
  }
  
  
}

class CommonPage extends React.Component{
  constructor(props) {
    super(props)
    
    this.state = {
      image_count: 2,
      bgStyle : {
        
      },
    }
  }

  componentWillMount() {
    const backgroundImageArray = [bgImage0, bgImage1,bgImage2,bgImage3,bgImage4,bgImage5,bgImage6];
    // const randomIndex = Math.floor(Math.random() * backgroundImageArray.length);
    const date = new Date()
    const day = date.getDate();
    const randomIndex = day % 7;
    const selectedImage = window.location.origin+backgroundImageArray[randomIndex];

    // Set title
    document.title=this.props.title

    // Set background
    this.setState({
      bgStyle: {
        backgroundImage: `url(${selectedImage})`,
        height: '100vh',
        width: '100vw',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        border: '0px',
        spacing: '0px',
        maxWidth: '100vw',
        maxHeight: '100vh'
      }
    })
  }


  render(){
    return(
      <Box>
      <Box className="login-container" style={this.state.bgStyle}>
        <Box className="content-box" sx={{ display: (this.props.pagetype === "blankPage" ? 'none' : 'block') }}>
          {loadModule(this.props, appInsights)}
        </Box>
      </Box>
      <Box>
        <Box className="footer">
          <Box>&copy; Copyright {(new Date().getFullYear())} MSCI Inc. All rights reserved.</Box>
          <Box className="footer-link-container">
            <a href="https://www.msci.com/legals" target="_new">Terms of Use</a>|
            <a href="https://www.msci.com/privacy-pledge" target="_new">Privacy Notice</a>|
            <a href="https://www.msci.com/cookie-policy" target="_new">Cookies</a>|
            <a href="https://www.msci.com/contact-us" target="_new">Contact Us</a>
          </Box>
        </Box>
      </Box>
    </Box>
    )
  }
}


export default CommonPage;

